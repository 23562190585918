import {
  createForm,
  email,
  required,
  type SubmitHandler,
} from '@modular-forms/solid';
import { createSignal } from 'solid-js';
import { hubspotJoinNewsletter } from '~/components/actions/hubspot';
import { Button, TextField } from '~/components/ui';
import { showAlert } from '~/utils';

type EmailSchema = {
  email: string;
};

export function FooterEmailForm() {
  const [_, { Form, Field }] = createForm<EmailSchema>();
  const [isSubmitted, setIsSubmitted] = createSignal(false);

  const handleSubmit: SubmitHandler<EmailSchema> = values => {
    try {
      setIsSubmitted(true);

      hubspotJoinNewsletter({
        email: values.email,
        onSuccess: () =>
          showAlert('Email sent successfully.', {
            type: 'success',
            duration: 4000,
            description: 'Thank you for subscribing to our newsletter!',
          }),
        onFailure: () =>
          showAlert('Email was not submitted. Try again later!', {
            type: 'error',
          }),
      });
    } catch (err) {
      console.error('Error submitting email to hubspot');
    } finally {
      setTimeout(() => setIsSubmitted(false), 3000);
    }
  };

  return (
    <Form
      class="w-full flex flex-col gap-3 items-start"
      onSubmit={handleSubmit}
    >
      <p class="font-bold text-lg leading-tight">
        Stay Updated on What's Coming
      </p>

      <Field
        name="email"
        type="string"
        validate={[
          required('Email is required'),
          email('Email should be correct'),
        ]}
      >
        {(field, props) => (
          <TextField
            {...props}
            {...field}
            error={field.error}
            hasError={!!field.error}
            class="max-w-sm w-full"
            inputClass="dark:bg-transparent dark:text-white"
            size="lg"
            placeholder="Your email"
            autocomplete="email"
          />
        )}
      </Field>

      <Button
        type="submit"
        variant={isSubmitted() ? 'secondary' : 'primary'}
        size="lg"
        disabled={isSubmitted()}
      >
        {isSubmitted() ? 'Sent' : 'Join The Newsletter'}
      </Button>
    </Form>
  );
}
