import { For } from 'solid-js';
import { BaseAccordion, Button, Divider, SocialLinks } from '~/components/ui';
import { FOOTER_NAV_ITEMS, type FooterPart } from '~/data/footer';
import { cn } from '~/utils';
import { FooterEmailForm } from './FooterEmailForm';

export function Footer() {
  return (
    <footer
      class="border-t border-slate-300 dark:border-neutral-700 mt-12 md:mt-16
        px-4"
    >
      <div
        class="mx-auto max-w-7xl flex flex-col gap-3 py-8 lg:flex-row-reverse
          md:justify-between md:pt-12"
      >
        <div class="flex flex-[2] flex-col gap-3 md:flex-row md:justify-around">
          {/* <Divider class="md:hidden" /> */}

          <div class="flex flex-1 gap-10 sm:gap-12 self-center pt-3 w-full">
            <ul class="flex-1 space-y-5">
              <For each={FOOTER_NAV_ITEMS.left}>
                {([label, url]) => <FooterListItem label={label} url={url} />}
              </For>
            </ul>

            <ul class="flex-1 space-y-5">
              <For each={FOOTER_NAV_ITEMS.right}>
                {([label, url]) => <FooterListItem label={label} url={url} />}
              </For>

              <FooterListItem
                label="Consent Preferences"
                url="#"
                linkClass="termly-display-preferences"
              />

              <FooterListAccordion
                label="Legal"
                items={FOOTER_NAV_ITEMS.legal}
              />
            </ul>
          </div>

          <div class="space-y-8 md:space-y-10">
            <SocialLinks shape="circle" action="follow" />
            <FooterEmailForm />
          </div>
        </div>

        <div class="flex flex-1 justify-center pt-4 md:pt-3">
          <div class="flex flex-col items-center gap-2 md:w-44 md:items-start">
            <span class="text-2xl font-bold md:mx-0 dark:text-white">
              DreamFlare
            </span>
            <p class="text-xs font-medium text-sky-500 dark:text-sky-300">
              {new Date().getFullYear()} © DreamFlare Inc.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

interface FooterListItemProps {
  label: string;
  url: string;
  linkClass?: string;
}

function FooterListItem(props: FooterListItemProps) {
  return (
    <li class="text-sm leading-none">
      <Button
        class={cn(
          'h-fit whitespace-nowrap p-0 font-bold dark:text-white',
          props.linkClass,
        )}
        variant="link"
        href={props.url}
      >
        {props.label}
      </Button>
    </li>
  );
}

interface FooterListAccordionProps {
  label: string;
  items: FooterPart;
}

function FooterListAccordion(props: FooterListAccordionProps) {
  return (
    <BaseAccordion
      title={props.label}
      headingClass="text-sm font-bold"
      icons={{
        wrapperClass: 'text-2xs ml-2',
        expandIcon: '▼',
        shrinkIcon: '▲',
      }}
    >
      <div class="space-y-2.5 w-fit mt-3">
        <Divider class="bg-slate-300" />
        <ul class="space-y-5 w-fit ">
          <For each={props.items}>
            {([label, url]) => (
              <FooterListItem
                linkClass="font-semibold"
                label={label}
                url={url}
              />
            )}
          </For>
        </ul>
        <Divider class="bg-slate-300" />
      </div>
    </BaseAccordion>
  );
}
