import { link } from '~/utils';

export type FooterPart = [string, string][];
type FooterNavItems = {
  left: FooterPart;
  right: FooterPart;
  legal: FooterPart;
};

export const FOOTER_NAV_ITEMS: FooterNavItems = {
  left: [
    // ['Free this Week', link.toFreeThisWeek()],
    // ['Top 10 Shows', link.toCategory({ category: 'top_10' })],
    ['Popular Now', link.toCategory({ category: 'trending' })],
    ['New Releases', link.toCategory({ category: 'new_releases' })],
    ['Become a Creator', link.toBecomeACreator()],
    // ['Get Premium', link.toMembershipPage()],
    // ['Shop', link.toShop()],
  ],
  right: [
    ['About Us', link.toAbout()],
    ['Investors & Press', 'mailto:info@dreamflare.ai'],
  ],
  legal: [
    ['Privacy Policy', link.toPrivacyPolicy()],
    ['Terms of Service', link.toTermsOfService()],
    ['Cookie Policy', link.toCookiePolicy()],
    ['Refund Policy', link.toRefundPolicy()],
    ['DMCA Policy', link.toDMCAPolicy()],
  ],
} as const;
